<template>
    <div class="resetPwd">
        <div class="head" @click="$router.go(-1)">
            <svg-icon iconClass="leftArrow" class="backBtn"></svg-icon>
        </div>
        <div class="title">重置密码</div>
        <div class="inputBox">
            <van-field placeholder="请输入密码" type="tel" v-model="pwdValue" />
        </div>
        <div class="inputBox mt24">
            <van-field placeholder="请再次输入密码" v-model="confirmPwd" />
        </div>
        <div class="nextBtn" @click="reset">密码重置</div>
    </div>
</template>

<script>
    import {
        resetpassword
    } from "@/api/user.js";
    export default {
        data() {
            return {
                pwdValue: '',
                confirmPwd: '',
            }
        },
        created() {},
        methods: {
            async reset() {
                if (!this.pwdValue) {
                    this.$toast('请输入密码');
                    return;
                }
                if (this.pwdValue != this.confirmPwd) {
                    this.$toast('两次密码不一致');
                    return;
                }
                let req = {
                    sign: sessionStorage.getItem('resetInfo'),
                    passWord: this.pwdValue,
                }
                this.$toast.loading({
                    duration: 0,
                });
                let ret = await this.$Api(resetpassword, req);
                if (ret && ret.code === 200) {
                    sessionStorage.removeItem("resetInfo");
                    this.$router.push('/login');
                    this.$toast('修改成功');
                } else if (ret.tip) {
                    this.$toast(ret.tip || '修改失败');
                }
                this.$toast.clear();
            }
        }
    }
</script>

<style lang='scss' scoped>
    .resetPwd {
        background: #f6f6f6;

        .head {
            width: 48px;
            height: 44px;
            display: flex;
            align-items: center;

            .backBtn {
                width: 16px;
                height: 16px;
                margin: 0 16px;
            }
        }

        .title {
            margin: 12px 0 34px;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
        }

        .inputBox {
            overflow: hidden;
            width: 278px;
            height: 40px;
            border-radius: 20px;
            border: 1px solid $greyishBrown;
            margin: 0 auto;
            background: $white1;
            display: flex;
            align-items: center;

            .lock {
                width: 20px;
                height: 20px;
                margin-left: 20px;
            }

            .areaCodeInp {
                width: 80px;
                padding: 0 3px;
                box-sizing: border-box;
                border-right: 1px solid $greyishBrown;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .phoneInp {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 198px;

                input {
                    width: 70%;
                }
            }
        }

        .nextBtn {
            width: 278px;
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            font-weight: 600;
            border-radius: 27px;
            margin: 0 auto;
            text-align: center;
            color: $white1;
            background: $vermillion;
            margin-top: 28px;
        }

        .mt24 {
            margin-top: 24px;
        }
    }
</style>